import { Box, LinearProgress } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import "./index.css";
// import NotFound from "./pages/NotFound";
import "./styles/global.css";
import theme from "./theme";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const Services = lazy(() => import("./pages/Services"));
const Testimonials = lazy(() => import("./pages/Testimonials"));
const Contact = lazy(() => import("./pages/Contact"));
// const Form = lazy(() => import('./pages/Form'))

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Box className="flex min-h-screen flex-col">
            <Navbar />
            <Suspense
              fallback={
                <Box className="w-full">
                  <LinearProgress />
                </Box>
              }
            >
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/testimonials" element={<Testimonials />} />
                <Route path="/contact" element={<Contact />} />
                {/* <Route path="/form" element={<Form />} /> */}
                {/* <Route path="*" element={<NotFound />} /> */}
              </Routes>
            </Suspense>
            <Footer />
          </Box>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
