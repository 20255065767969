import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { ZROW_LOGO } from "../images";

const StickyFooter = () => {
  return (
    <Box className="mt-auto bg-dark-blue py-3 text-white" component="footer">
      <Grid className="flex flex-col items-center gap-4">
        <Grid className="flex flex-col items-center gap-4 md:flex-row">
          <Grid className="order-1 flex items-center justify-center md:order-2">
            <img src={ZROW_LOGO} className="h-auto w-full max-w-xs p-5" />
          </Grid>

          <Grid className="order-2 flex flex-col items-center md:order-1">
            <h4 className="text-3xl text-yellow-400">Email</h4>
            <p className="text-lg underline">john.doe@gmail.com</p>
          </Grid>

          <Grid className="order-3 flex flex-col items-center md:order-3">
            <h4 className="text-3xl text-yellow-400">Phone</h4>
            <p className="text-lg underline">07720999931</p>
          </Grid>
        </Grid>

        <Grid className="text-center">© 2024 ZROW COACHING. All rights reserved.</Grid>
      </Grid>
    </Box>
  );
};

export default StickyFooter;
